import axios from 'axios';
import { useSetRecoilState } from 'recoil';
import { authAtom } from 'auth/state';
import { loadingAtom } from 'common/state';

// Count of all in-progress requests of all useAxios instances.
var requestCounter = 0;

function useAxios({ auto_logout = true }) {
    const setAuth = useSetRecoilState(authAtom);
    const setLoading = useSetRecoilState(loadingAtom);
    const axiosInstance = axios.create();

    function incrementRequestCounter() {
        requestCounter += 1
        if (requestCounter > 0) setLoading(true)
    }

    function decrementRequestCounter() {
        requestCounter -= 1
        if (requestCounter <= 0) setLoading(false)
    }

    axiosInstance.interceptors.request.use(function (config) {
        // Do something before request is sent
        incrementRequestCounter()
        return config;
    },
    function (error) {
        // Do something with request error
        decrementRequestCounter()
        return Promise.reject(error);
    });

    axiosInstance.interceptors.response.use(
        function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            decrementRequestCounter()
            return response
        },
        function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            console.warn("useAxios error: " + error)
            decrementRequestCounter()
            if (auto_logout && error.response && [401, 403].includes(error.response.status)) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                console.warn("auto logout if 401 Unauthorized or 403 Forbidden response returned from api")
                setAuth(false)
            }

            return Promise.reject(error);
        });

        return axiosInstance
}

export { useAxios }
