import React from 'react';
import { useParams, useHistory } from "react-router-dom";
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grow from '@mui/material/Grow';

import {
    UNKNOWN_ERROR_MSG,
    emailValidator,
    minLength8Validator,
    printableAsciiValidator,
    requiredValidator,
    useInput,
    getHelperText,
} from './form.js'

import { useAuthActions } from 'auth/actions';

const USER_NAME_VALIDATORS = [requiredValidator]
const USER_EMAIL_VALIDATORS = [requiredValidator, emailValidator]

const USER_PASSWORD_VALIDATORS = [
    requiredValidator,
    minLength8Validator,
    printableAsciiValidator,
]

const RegistrationForm = ({
        userNameProps,
        userEmailProps,
        userPassword1Props,
        userPassword2Props,
        eulaAccepted,
        handleEulaAcceptedChange,
        handleSubmit,
        alertText,
}) => {
    return (
        <Grid container spacing={2} maxWidth="sm">
            <Grid item xs={12}>
                <TextField fullWidth
                    /*autoFocus*/
                    id="register-name"
                    label="Name"
                    type="string"
                    autoComplete="name"
                    variant="outlined"
                    required
                    { ...userNameProps }
                />
            </Grid>
            <Grid item xs={12}>
                <TextField fullWidth
                    id="register-email-address"
                    label="Email address"
                    type="email"
                    autoComplete="email"
                    variant="outlined"
                    required
                    { ...userEmailProps }
                />
            </Grid>
            {
                [
                    {...userPassword1Props, label: 'Password', key: 1},
                    {...userPassword2Props, label: 'Repeat password', key: 2},
                ].map(
                    (props, ix) => (
                        <Grid item xs={12} key={`register-password-grid-${ix}`}>
                            <TextField fullWidth
                                id={`register-password-${ix + 1}`}
                                type="password"
                                autoComplete="new-password"
                                variant="outlined"
                                required
                                { ...props }
                            />
                        </Grid>
                    ))
            }
            <Grid item xs={12}>
                <FormControlLabel
                    id="eula-switch-label"
                    control={(
                        <Switch
                            id="eula-switch"
                            checked={eulaAccepted}
                            onChange={handleEulaAcceptedChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    )}
                    label={
                        <div>
                            <span>I have read and agree to the terms and conditions in the </span>
                            <Link
                                href="https://premoni-staging.s3.eu-west-2.amazonaws.com/Premonition_End_User_Licence_Agreement.pdf"
                                target="_blank"
                            >
                                End&nbsp;User&nbsp;Licence&nbsp;Agreement
                            </Link>
                            <span> and confirm that I am a real person. *</span>
                        </div>
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <Grow in={alertText !== ''}>
                    <div>{ alertText ? <Alert severity="error">{alertText}</Alert>: null }</div>
                </Grow>
            </Grid>
            <Grid container justifyContent="flex-end" pt={2}>
                    <Button
                        id={"register-submit"}
                        variant="contained"
                        onClick={handleSubmit}>
                        Register
                    </Button>
            </Grid>
        </Grid>
    )
}

const EmailUnchangedOutcomeContent = ({onClose}) => (
    <Grid container spacing={2} maxWidth="sm">
        <Grid item xs={12}>
            <Typography variant="body1">
                Thank you for registering.
            </Typography>
            <Typography variant="body1">
                To continue, please log in.
            </Typography>
        </Grid>
        <Grid container justifyContent="flex-end" pt={2}>
            <Button id={"register-ok"} variant="contained" onClick={onClose}>OK</Button>
        </Grid>
    </Grid>
)

const EmailChangedOutcomeContent = ({onClose}) => (
    <Grid container spacing={2} maxWidth="sm">
        <Grid item xs={12}>
            <Typography variant="body1">
                Thank you for registering.
            </Typography>
            <Typography variant="body1">
                As a final step, we have sent you an email to confirm your email address.
            </Typography>
            <Typography variant="body1">
                After opening that email and clicking the link contained, you will be able to log in.
            </Typography>
        </Grid>
        <Grid container justifyContent="flex-end" pt={2}>
            <Button id={"register-ok"} variant="contained" onClick={onClose}>OK</Button>
        </Grid>
    </Grid>
)

const RegisterDialog = ({open, setOpen}) => {
    let { code: _code } = useParams('code');
    let code = _code?.toLowerCase();
    var history = useHistory();

    const [userNameProps, setUserName, setUserNameHelperText] = useInput(USER_NAME_VALIDATORS);
    const [userEmailProps, setUserEmail, setUserEmailHelperText] = useInput(USER_EMAIL_VALIDATORS);
    const [userPassword1Props, _setUserPassword1, setUserPassword1HelperText] = useInput(USER_PASSWORD_VALIDATORS);
    const [userPassword2Props, _setUserPassword2, setUserPassword2HelperText] = useInput(USER_PASSWORD_VALIDATORS);

    const authActions = useAuthActions();

    const [eulaAccepted, setEulaAccepted] = React.useState(false);
    const [alertText, setAlertText] = React.useState('');
    const [userEmailUnchanged, setUserEmailUnchanged] = React.useState(null);
    const [preloadSuccess, setPreloadSuccess] = React.useState(null);

    React.useEffect(() => {
        (async () => {
            if (!code) {
                console.warn('RegisterDialog useEffect no code')
                return
            }

            // UUID
            if (!code.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/)) {
                console.warn('register useEffect - code not a UUID')
                history.push('/')
                return
            }

            try {
                var response = await authActions.getPreRegUserDetails(code)
            } catch (error) {
                console.warn(`register useEffect - getPreRegUserDetails error response: ${JSON.stringify(response)}`)
                history.push('/')
                return
            }

            setUserName(response.data.name);
            setUserEmail(response.data.email);
            setPreloadSuccess(true);
        })();
        // eslint-disable-next-line
    }, []) // empty dependencies, so single shot on component creation.

    const resetForm = () => {
        setUserName('')
        setUserNameHelperText('')
        setUserEmail('')
        setUserEmailHelperText('')
        _setUserPassword1('')
        setUserPassword1HelperText('')
        _setUserPassword2('')
        setUserPassword2HelperText('')
        setEulaAccepted(false)
        setAlertText('')
        setUserEmailUnchanged(null)
        setPreloadSuccess(null)
    }

    const validateForm = () => {
        // getHelperText runs the validators
        var invalidNameMessage = getHelperText(userNameProps.value, USER_NAME_VALIDATORS)
        if (invalidNameMessage) setUserNameHelperText(invalidNameMessage)

        var invalidEmailMessage = getHelperText(userEmailProps.value, USER_EMAIL_VALIDATORS)
        if (invalidEmailMessage) setUserEmailHelperText(invalidEmailMessage)

        var invalidPassword1Message = getHelperText(userPassword1Props.value, USER_PASSWORD_VALIDATORS)
        if (invalidPassword1Message) setUserPassword1HelperText(invalidPassword1Message)

        var invalidPassword2Message = getHelperText(userPassword2Props.value, USER_PASSWORD_VALIDATORS)
        if (invalidPassword2Message) setUserPassword2HelperText(invalidPassword2Message)

        if (invalidNameMessage || invalidEmailMessage || invalidPassword1Message || invalidPassword2Message)
            return false

        if (userPassword1Props.value !== userPassword2Props.value) {
            setAlertText('Passwords do not match');
            return false
        }

        if (!eulaAccepted) {
            setAlertText('End User License Agreement not accepted');
            return false
        }

        return true
    }

    const handleEulaAcceptedChange = (event) => {
        setEulaAccepted(event.target.checked);
    };

    const handleSubmit = async () => {

        if (!validateForm()) return

        const userDetails = {
            name: userNameProps.value,
            email: userEmailProps.value,
            password: userPassword1Props.value,
            password2: userPassword2Props.value,
            eula_accepted: true,
        }

        const headers = {
            'Content-Type': 'application/json',
        }

        try {
            var response = await authActions.register(code, userDetails, headers)
            setUserEmailUnchanged(response.data === 'Email Unchanged');
        } catch (error) {
            // Show response body text if present, otherwise default error message.
            if (error.response.data) setAlertText(error.response.data)
            else setAlertText(UNKNOWN_ERROR_MSG)
        }
    };

    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick') return
        setOpen(false)
        resetForm()
        history.replace('/')
    }

    var content = null
    if (userEmailUnchanged === null) {
        const formProps = {
            userNameProps,
            userEmailProps,
            userPassword1Props,
            userPassword2Props,
            eulaAccepted,
            handleEulaAcceptedChange,
            alertText,
            handleSubmit,
        }

        content = preloadSuccess === null ? null : <RegistrationForm {...formProps} />
    }
    else {
        content = <Grid container spacing={2}>
                <Grid item xs={12}>
                    { userEmailUnchanged ? <EmailUnchangedOutcomeContent onClose={handleClose}/>
                        : <EmailChangedOutcomeContent onClose={handleClose}/> }
                </Grid>
            </Grid>
    }

    return <Dialog open={open} onClose={handleClose} maxWidth="sm">
            <DialogTitle>Register</DialogTitle>
            <IconButton aria-label="close" onClick={handleClose}
                    sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
                <CloseIcon />
            </IconButton>

            <form>
            <DialogContent>{content}</DialogContent>
            </form>
        </Dialog>
}

export { RegisterDialog };
