import { useEffect } from 'react';
import { useLocation, Redirect, Route } from "react-router-dom";
import { useRecoilValue } from 'recoil';

import { authAtom } from 'auth/state';
import { useAuthActions } from 'auth/actions';
import { useInitialRedirectHelper } from 'common/initial-redirect-helper';

function AuthProvider({ children }) {
    const authActions = useAuthActions();
    const initialRedirectHelper = useInitialRedirectHelper();

    // On full page load, asynch check against server if logged in through an info api call. We set auth recoil state
    // to false or to the user auth info recived. The auth recoil state is initialised to null, indicating this pending
    // check.
    // NOTE - We will only subsequently detect an expired session when a server API call is made.
    useEffect(() => {
        (async () => {
            try {
                const localInfo = await authActions.fetchAuthInfo()
                // SSO Users do not submit the login dialog, so check for initial redirect here also.
                await initialRedirectHelper.initialRedirect(localInfo)
            } catch (error) {
                console.debug('AuthProvider useEffect not logged in')
            }
        })();
        // eslint-disable-next-line
    }, [])  // empty dependencies, so single shot on component creation.

    return children;
}

function AuthContent(props) {
    const userCan = useUserCan().userCan

    if (!userCan({permission: props.permission, contextStr: 'for AuthContent'})) {
        return null
    }

    return props.children;
}

const PrivateRoute = (props) => {
    const location = useLocation();
    const userCan = useUserCan().userCan

    var allowed = userCan({permission: props.permission, contextStr: `for PrivateRoute ${props.path}`})

    if (allowed === null) {
        return null
    }

    return allowed ? (
      <Route {...props} />
    ) : (
      <Redirect to={{ pathname: "/", state: { from: location } }} replace />
    );
};

function useUserCan() {
    const auth = useRecoilValue(authAtom);

    return {
        userCan
    }

    function userCan({ permission=null, contextStr="" }) {
        /**
         * Indicates if the use has the requested permission.
         *  - Unauthenticated users can do nothing.
         *  - Authenticated admin users can do anything.
         *  - Authenticated users are allowed specific permissions.
         *  - The undefined / null permission is supported and used to protect areas that require authentication.
         *
         * Additional permissions would be checked against the union of permissions from all the user's roles.
         *
         * Returns:
         *     null: if auth provider is pending
         *     false: if the user is not authenticated or does not have the requested permission
         *     true: if the authenticated user has the requested permission (null permission is supported)
         */

        if (auth === null) {
            // Pending auth provider.
            logAccessOutcome(permission, false, 'auth provider pending', contextStr)
            return null
        }

        if (auth === false) {
            // Not authenticated
            logAccessOutcome(permission, false, 'as not authenticated', contextStr)
            return false
        }

        // Authenticated from here on ...

        if (auth.admin) {
            // admin is like a superadmin - can do any action.
            logAccessOutcome(permission, true, 'as admin', contextStr)
            return true
        }

        if (permission === null) {
            // Every authenticated user can perform an action where no permission is requested.
            logAccessOutcome(permission, true, 'as no permission is required', contextStr)
            return true
        }

        // FIXME more complex permission checks would go here.
        // permission is like <ACTION>_<RESOURCETYPE> eg READ_APP, UPDATE_USER
        //
        // Anticipate supporting CRUD actions plus
        //   - possibly VIEW (a shallow list permission)
        //   - more specific update actions where necessary (eg APPROVE_MEASURE)
        //
        // Permissions to be allowed specifically for resources identified by id - resource target param would be added
        // Permissions per app/org to be supported - app / org id params would be added if not implicit from target
        // Roles are collections of permissions

        // Default is not allowed
        logAccessOutcome(permission, false, 'by default', contextStr)
        return false
    }

    function logAccessOutcome(permission, allowed, reason, contextStr) {
        //var allowedStr = `${allowed ? "can" : "can NOT"}`
        //var permissionStr = permission ? permission : "access authenticated resources"
        //console.debug(`userCan - User ${allowedStr} ${permissionStr} ${reason} ${contextStr}`)
    }
}

export { AuthProvider, AuthContent, PrivateRoute };
