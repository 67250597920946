import { useSetRecoilState } from 'recoil';
import { useAxios } from 'common/use-axios';
import { appsAtom } from 'app/state';


function useAppActions() {
    const setApps = useSetRecoilState(appsAtom);
    const axios = useAxios({});

    const resourceBasePath = '/api/v1/app';

    return {
        fetchAll,
        create,
        update,
        remove
    }

    async function fetchAll() {
        const res = await axios.get(resourceBasePath)
        setApps(res.data.data);
    }

    async function create(data) {
        await axios.post(resourceBasePath, data)
        await fetchAll()
    }

    async function update(id, data) {
        await axios.put(`${resourceBasePath}/${id}`, data)
        await fetchAll()
    }

    async function remove(id) {
        await axios.delete(`${resourceBasePath}/${id}`)
        await fetchAll()
    }
}

export { useAppActions };
