import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import { useSetRecoilState } from 'recoil';
import { contactDialogOpenAtom } from 'auth/state';

const LoginAppBar = (props) => {
    const setContactDialogOpen = useSetRecoilState(contactDialogOpenAtom)

    return <AppBar position="fixed">
        <Toolbar>
            <Grid container alignItems="center">
                <Grid item xs={1}>
                    <Grid container alignItems="center">
                        <img src="/P_logo_transparent_40.png" alt="Premoni P Logo" width='40' />
                    </Grid>
                </Grid>
                <Grid item xs={11}>
                    <Grid container justifyContent="right">
                        <Button href="https://premonition.software/premoni.html" target="_blank">About</Button>
                        <Button onClick={() => {setContactDialogOpen(true)}}>Contact</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Toolbar>
    </AppBar>
}

export { LoginAppBar }
