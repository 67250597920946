import { useHistory } from 'react-router-dom';

function useInitialRedirectHelper() {
    const history = useHistory();

    return {
        initialRedirect,
        getFinalDestination
    }

    async function getFinalDestination() {
        var queryParams = new URLSearchParams(window.location.search)

        var finalDestination = ''
        if (queryParams.get('final_destination')) {
            finalDestination += queryParams.get('final_destination')
            if (history.location.hash) finalDestination += history.location.hash
        }
        console.debug(`final_destination param plus inherired fragment: ${finalDestination}`)

        return finalDestination
    }

    async function initialRedirect(localInfo) {
        // Check for final_destiation. Redirect or navigate if set.
        const finalDestination = await getFinalDestination()
        if (finalDestination) {
            // console.debug(`Redirecting to query param final_destination ${finalDestination} plus inherired fragment`)
            if (finalDestination.startsWith('http')) {
                window.location = finalDestination
            } else {
                console.warn(`unsupported finalDestination ${finalDestination}`)
            }
            return
        }

        // If the user is not an admin and has only one allowed app, redirect to that app.
        if (!localInfo.admin && localInfo.apps.length === 1) {
            var appUrl = localInfo.apps[0].url
            // console.debug(`Redirecting to the sole configured app of a non-admin user url: ${appUrl}`)
            window.location = appUrl
            return
        }

        // If react history had a location we were on the way to before login, navigate to that.
        // Otherwise, navigate to home view.
        var from = history.location.state?.from?.pathname || '/'
        //console.debug(`login - success navigate to history location state from: ${from}`)
        history.push(from)
    }
}

export { useInitialRedirectHelper };
