import { deepmerge } from '@mui/utils';
import { createTheme } from '@mui/material/styles';

const stdThemeOptions = {
    palette: {
        mode: 'light',

        primary: {
            light: '#57A7B5',
            main: '#1B8793',  // teal
            dark: '#156871',
            contrastText: '#fff',
        },
        secondary: {
            light: '#8C8CB3',
            main: '#5A5A8F',  // dark blue
            dark: '#42426A',
            contrastText: '#fff',
        },
        error: {
            light: '#DB4333',
            main: 'rgba(193, 52, 37, 0.5)',  //  red
            dark: '#93271B',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        warning: {
            light: '#FDE377',
            main: 'rgba(254, 197, 3, 0.5)',    // yellow
            dark: '#FEC202',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        info: {
            light: '#58ADE3',
            main: '#227EB9',       // blue
            dark: '#1A628E',
            contrastText: '#fff',
        },
        success: {
            light: '#A4CA2C',
            main: 'rgba(107, 156, 28, 0.5)',   // green
            dark: '#496A14',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        typography: {
            fontFamily: '"Open Sans", "Helvetica", "Arial", "sans-serif"',
            subtitle2: {
                fontSize: '0.8em'
            }
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    color: 'white'
                },
                contained: {
                    textTransform: 'none'
                },
            },
        }
    },
}

const loginThemeOptions = {
    palette: {
        background: {
            default: '#373536'
        },

        primary: {
            light: '#61c1d0',
            main: '#57A7B5',  // turquoise
            dark: '#497e84',
            contrastText: '#fff',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    '&:hover': {
                        backgroundColor: '#fec503'
                    },
                },
                contained: {
                    textTransform: 'uppercase',
                }
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#373536',
                    '& .MuiButton-root': {
                        fontSize: 20
                    },
                    '& .MuiButton-root:hover': {
                        color: '#fec503',
                        backgroundColor: 'transparent'
                    }
                }
            }
        },

        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    color: '#1B8793'
                }
            }
        }
    },
}

const stdTheme = createTheme(stdThemeOptions)
const loginTheme = createTheme(deepmerge(stdThemeOptions, loginThemeOptions))

export { stdTheme, loginTheme }
