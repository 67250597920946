import { useSetRecoilState } from 'recoil';
import { useAxios } from 'common/use-axios';
import { authAtom } from 'auth/state';


function useAuthActions() {
    const setAuth = useSetRecoilState(authAtom);
    const axios = useAxios({ auto_logout: false });

    return {
        fetchAuthInfo,
        preLogin,
        login,
        logout,
        getPreRegUserDetails,
        register,
        confirm,
        contact,
    }

    async function fetchAuthInfo() {
        try {
            const infoResp = await axios.get('/api/v1/info')
            setAuth(infoResp.data)
            return infoResp.data
        } catch (error) {
            setAuth(false)
            throw error
        }
    }

    async function preLogin(email, final_destination) {
        var post_data = { email, final_destination }
        var response = await axios.post('/api/v1/pre_login', post_data)
        return response.data
    }

    async function login(email, password) {
        const loginResponse = await axios.post('/api/v1/login', { email, password })
        return loginResponse
    }

    async function logout() {
        await axios.get('/api/v1/logout')
        setAuth(false)
        return false
    }

    async function getPreRegUserDetails(code) {
        const response = await axios.get(`/api/v1/pre-register/${code}`)
        return response
    }

    async function register(code, userDetails, headers) {
        const response = await axios.post(`/api/v1/register/${code}`, userDetails, {headers: headers})
        return response
    }

    async function confirm(code) {
        const response = await axios.get(`/api/v1/confirm/${code}`)
        return response
    }

    async function contact(data) {
        const response = await axios.post('/api/v1/contact', data)
        return response
    }
}

export { useAuthActions };
