import React from 'react';

import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { useRecoilValue } from 'recoil';
import { authAtom } from 'auth/state';

function HomeContent() {
    const auth = useRecoilValue(authAtom);
    return <React.Fragment>
        <Card><CardContent>
            <Typography variant="h5" textAlign="center">
                Welcome, {auth.name}
            </Typography>
            <Typography variant="h6" textAlign="center">
                {auth.apps.length > 0 ? 'Please select one of the links below.'
                    : <span><WarningIcon /> Sorry: your account set up is not complete.</span>
                }
            </Typography>
        </CardContent></Card>
        <List>{auth.apps.map((app) => (
            <ListItem key={app.url}>
                <ListItemText><Link href={app.url} underline="none">{app.name}</Link></ListItemText>
            </ListItem>
        ))}</List>
    </React.Fragment>
}

export { HomeContent };
