import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { Alert } from '@mui/material';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


import React from 'react';

import { EditMenu } from 'App'
import { Feedback } from 'App'
import { ListField } from 'App'
import { stdTheme } from 'common/themes';

import {
    atom,
    useRecoilState,
    useRecoilValue,
    useSetRecoilState,
} from 'recoil';

import { useAppActions } from 'app/actions';

import { appsAtom } from 'app/state';

import { AuthContent } from 'auth/components/auth-provider';

const selectedApp = atom({key: 'selectedApp', default: null});


const AppsView = () => {
    const apps = useRecoilValue(appsAtom);
    const [appDialogOpen, setAppDialogOpen] = React.useState(false);
    const setCurrentApp  = useSetRecoilState(selectedApp);
    const [error, setError] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [deleteConfirm, setDeleteConfirm] = React.useState(false);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [snackbarLevel, setSnackbarLevel] = React.useState(null);
    const showFeedback = (message, level) => {
        setSnackbarOpen(true);
        setSnackbarMessage(message);
        setSnackbarLevel(level);
    }
    const appActions = useAppActions();

    React.useEffect(() => {
        (async () => { appActions.fetchAll() })();
        // eslint-disable-next-line
    }, []);  // empty dependencies, so single shot on component creation.

    const openAppDialog = () => {
        setAppDialogOpen(true);
    };
    const closeAppDialog = () => {
        setAppDialogOpen(false);
    };
    const openAppEditMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setDeleteConfirm(false);
    };
    const createApp = (e) => {
        e.preventDefault();
        setCurrentApp({name: '', url: ''});
        setError('');
        openAppDialog();
    }

    return <Container fixed>
        {!apps ? null : <React.Fragment>
            <AppDialog appDialogOpen={appDialogOpen} closeAppDialog={closeAppDialog}
                error={error} setError={setError} showFeedback={showFeedback} />
            <Feedback open={snackbarOpen} setOpen={setSnackbarOpen} message={snackbarMessage}
                level={snackbarLevel}/>
            <EditMenu openDialog={openAppDialog} anchorEl={anchorEl} setAnchorEl={setAnchorEl}
                setError={setError} selectedThing={selectedApp} thingsState={appsAtom}
                deleteConfirm={deleteConfirm} setDeleteConfirm={setDeleteConfirm}
                includeDelete={true} showFeedback={showFeedback} kind="app"/>
            <List>{apps.map((app) => (
                <ListItem key={app.url}>
                    <ListItemText primary={app.name} secondary={app.url} />
                    <ListItemSecondaryAction>
                        <IconButton onClick={openAppEditMenu} data-appid={app.id}
                            edge="end" aria-label="comments" size="large">
                            <MoreHorizIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            ))}</List>
            <AuthContent permission="CREATE_APP">
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item>
                        <Tooltip title="Create App">
                            <Fab color="primary" onClick={createApp}>
                                <AddIcon />
                            </Fab>
                        </Tooltip>
                    </Grid>
                </Grid>
            </AuthContent>
        </React.Fragment>}
    </Container>
}


const AppDialog = (props) => {
    const {appDialogOpen, closeAppDialog, error, setError, showFeedback} = props;
    const [currentApp, setCurrentApp] = useRecoilState(selectedApp);
    const appActions = useAppActions();

    const submitApp = async (e) => {
        e.preventDefault();
        var data = {
            name: currentApp.name,
            url: currentApp.url,
            client_permissions: currentApp.client_permissions || [],
            supplier_permissions: currentApp.supplier_permissions || [],
        };
        if (currentApp.is_legacy){
            data = {...data,'is_legacy':true}
        }

        try {
            var message = null
            if (currentApp.id) {
                await appActions.update(currentApp.id, data)
                message = 'Updated '
            } else {
                await appActions.create(data)
                message = 'Created '
            }
            message += ' app: ' + data.name
            closeAppDialog();
            showFeedback(message, 'success');
        } catch(error) {
            console.error('error:', JSON.stringify(error));
            setError(error.response.data || error.message);
        }
    };

    return <Dialog open={appDialogOpen} onClose={closeAppDialog} disableEscapeKeyDown>
        <DialogTitle id="edit-app-dialog-title" sx={{
            backgroundColor: stdTheme.palette.primary.main, color: '#fff'
        }}>
            <Typography variant="body2" sx={{
                textTransform: 'uppercase', fontWeight: 500
                }}>{currentApp && currentApp.id ? 'Edit App': 'Create App'}</Typography>
            <IconButton aria-label="close" sx={{
                    position: 'absolute', right: stdTheme.spacing(1), top: '2px',
                    color: stdTheme.palette.grey[500]
               }} onClick={closeAppDialog} size="large">
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
        <Grid container spacing={2} pt={2}>
            <Grid item xs={12}>
                <TextField autoFocus size="small" id="appName" label="App Name" type="text"
                    value={currentApp ? currentApp.name: ''} onChange={e => setCurrentApp({
                        ...currentApp, name: e.target.value
                    })} fullWidth required />
            </Grid>
            <Grid item xs={12}>
                <TextField size="small" id="appUrl" label="URL" type="text"
                    value={currentApp ? currentApp.url: ''} onChange={e => setCurrentApp({
                        ...currentApp, url: e.target.value
                    })} fullWidth required />
            </Grid>
            {currentApp && currentApp.id ? <React.Fragment>
            <Grid item xs={12}>
                <TextField size="small" id="appClientId" label="App Id" type="text"
                    value={currentApp.id} fullWidth disabled />
            </Grid>
            <Grid item xs={12}>
                <TextField size="small" id="appClientId" label="Client Id" type="text"
                    value={currentApp.client_id} fullWidth disabled />
            </Grid>
            <Grid item xs={12}>
                <TextField size="small" id="appClientSecret" label="Client Secret" type="text"
                    value={currentApp.client_secret} fullWidth disabled />
            </Grid>
            <Grid item xs={12}>
                <FormGroup>
                <FormControlLabel control={<Checkbox  checked={currentApp.is_legacy || false}onChange={(event)=>{
                    setCurrentApp(app=>{
                            return {...app, 'is_legacy':event.target.checked}
                    })
                }} />} label="Don't Manage Users or Permissions " />
                </FormGroup>
            </Grid>
        </React.Fragment>: null}
            {currentApp && !currentApp.is_legacy && <Grid item xs={12}>
                <FormLabel component="legend">Client Permissions</FormLabel>
                <ListField containingObj={currentApp} setContainingObj={setCurrentApp}
                    field="client_permissions"/>
                <FormLabel component="legend">Supplier Permissions</FormLabel>
                <ListField containingObj={currentApp} setContainingObj={setCurrentApp}
                    field="supplier_permissions"/>
            </Grid>}
            <Grid item xs={12}>
                {error ? <Alert severity="error">{error}</Alert>: null}
            </Grid>
        </Grid>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: stdTheme.palette.primary.main }}>
            <Button onClick={submitApp} >Save</Button>
            <Button onClick={closeAppDialog} >Cancel</Button>
        </DialogActions>
    </Dialog>
}

export { AppsView }
