import React from 'react';

import Container from '@mui/material/Container';

import { LoginView } from 'auth/components/login';
import { HomeContent } from 'home/components/home-content';

import { useRecoilValue } from 'recoil';
import { authAtom } from 'auth/state';

function HomeView() {
    const auth = useRecoilValue(authAtom);

    var component = null

    if (auth === null) {
        component = null
    } else if (auth === false) {
        component = <LoginView/>
    } else {
        component = <HomeContent/>
    }

    return <Container fixed>
        {component}
    </Container>
}

export { HomeView };
