import React from 'react';
import { useParams } from "react-router-dom";
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

import { UNKNOWN_ERROR_MSG } from './form.js';
import { useAuthActions } from 'auth/actions';

const UserConfirmedText = ({onClose}) => (
    <Grid container spacing={2} maxWidth="sm">
        <Grid item xs={12}>
            <Typography variant="body1">
                Thank you for confirming your email address.
            </Typography>
            <Typography variant="body1">
                To continue, please log in.
            </Typography>
        </Grid>
        <Grid container justifyContent="flex-end" pt={2}>
            <Button id={"confirm-ok"} variant="contained" onClick={onClose}>OK</Button>
        </Grid>
    </Grid>
)

const UserNotConfirmedText = ({onClose}) => (
    <Grid container spacing={2} maxWidth="sm">
        <Grid item xs={12}>
            <Typography variant="body1">
                Could not confirm your email address. Please contact support.
            </Typography>
        </Grid>
        <Grid container justifyContent="flex-end" pt={2}>
            <Button id={"confirm-ok"} variant="contained" onClick={onClose}>OK</Button>
        </Grid>
    </Grid>
)

const Main = ({userConfirmed, onClose}) => (
    userConfirmed === true ? <UserConfirmedText onClose={onClose} /> : <UserNotConfirmedText onClose={onClose} />
)

const ConfirmDialog = ({open, setOpen}) => {
    let { code: _code } = useParams('code');
    let code = _code?.toLowerCase();
    const [userConfirmed, setUserConfirmed] = React.useState(null);
    const authActions = useAuthActions();

    React.useEffect(() => {
        (async () => {
            try {
                var response = await authActions.confirm(code)
                setUserConfirmed(response.status === 200);
            } catch (error) {
                setUserConfirmed(false);
            }
        })();
        // eslint-disable-next-line
    }, []); // empty dependencies, so single shot on component creation.

    // UUID
    if (!code.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/)) {
        console.debug('confirm - code not a UUID')
        return <Alert severity="error">{UNKNOWN_ERROR_MSG}</Alert>
    }

    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick') return
        setOpen(false)
        setUserConfirmed(null)
    }

    var content = null
    if (userConfirmed === null) {
        content = null
    } else {
        content = <Main userConfirmed={userConfirmed} onClose={handleClose} />
    }

    return <Dialog open={open} onClose={handleClose} maxWidth="sm">
            <DialogTitle>Confirm</DialogTitle>
            <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}
                >
                <CloseIcon />
            </IconButton>

            <form>
            <DialogContent>{content}</DialogContent>
            </form>
        </Dialog>
}

export { ConfirmDialog };
