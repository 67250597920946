import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';

import { useTheme } from '@mui/material/styles';

import { useRecoilValue } from 'recoil';

import { loadingAtom } from 'common/state';
import { authAtom } from 'auth/state';

const Spinner = () => {
    return <Backdrop sx={{ color: '#fff', zIndex: 99999}} open={ true }>
        <CircularProgress/>
    </Backdrop>
};

const LoadingSpinner = ({ children }) => {
    const loading = useRecoilValue(loadingAtom);

    return <>
        { loading ? <Spinner/> : null}
        { children }
    </>
};

const StdAppBar = (props) => {
    const theme = useTheme()

    return <AppBar position="fixed">
        <Toolbar>
            <IconButton
                    edge="start"
                    sx={{marginRight: theme.spacing(2)}}
                    color="inherit"
                    aria-label="menu"
                    onClick={ props.toggleDrawer(true) }
                    size="large">
                <MenuIcon />
            </IconButton>
            <Typography variant="h6" sx={{flexGrow: 1}}/>
            <Button color="inherit" onClick={ props.onLogout }>Logout</Button>
        </Toolbar>
    </AppBar>
}

const StdFooter = () => {
    const info = useRecoilValue(authAtom);

    if (!info) return null

    return <Box sx={{
                position: 'fixed', bottom: '0', left: '0', right:'0', backgroundColor:'#eee',
                paddingTop:'5px', paddingBottom:'5px' }}
            >
                <Grid container direction="row" justifyContent="space-around" spacing={2}
                    sx={{fontSize:'75%' }}
                >
                    <Grid item xs={1}/>
                    <Grid item xs={3}>
                        {info.version ? <Box>{ info.version }{ info.env ? ' (' + info.env + ')': null}</Box>: null}
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container justifyContent="center">
                            © 2015-{ info.copyright_year } Premonition Software Limited
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent="flex-end">
                            <Link href="https://premoni.s3.eu-west-2.amazonaws.com/Premonition Privacy Policy.pdf"
                                underline="none" target="_blank">Privacy Policy</Link>
                        </Grid>
                    </Grid>
                    <Grid item xs={1}/>
                </Grid>
            </Box>
}

export { LoadingSpinner, StdAppBar, StdFooter };
