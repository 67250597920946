import React from 'react';

const REQUIRED_MSG = 'Required';
const NOT_VALID_EMAIL_MSG = 'Not a valid email address';
const VALIDATION_ERROR_MSG = 'Form validation error';
const MIN_LENGTH_8_MSG = 'Must be a minimum of 8 characters'
const NON_PRINTABLE_ASCII_MSG = 'One or more invalid characters'
export const UNKNOWN_ERROR_MSG = 'Unexpected error. Please contact support.';

export const NO_RESPONSE_MSG = 'No response from server';

export const getHelperText = (value, validators) => {
    let messages = validators.reduce((acc, validator) => [...acc, ...validator(value)], []);
    return messages[0];
};

export const requiredValidator = (v) => v.length === 0 ? [REQUIRED_MSG] : [];

export const emailValidator = (value) => {
    // This is based on the RegEx in the 'premoni2/premoni/schema.py',
    // but stricter around how it handles dots.

    // The backend validation is currently rejecting valid email
    // addresses (e.g. international email addresses), so we should
    // consider replacing both with something that conforms to RFC
    // standards.

    let [userName, domainName, ...cruft] = value.split('@');
    if (!userName || !domainName || cruft.length > 0) {
        return [NOT_VALID_EMAIL_MSG];
    }

    let userNameParts = userName.split('.');
    if (userNameParts.some((e) => !e.match(/^[A-Za-z0-9_+-]+$/))) {
        return [NOT_VALID_EMAIL_MSG];
    }

    let domainNameParts = domainName.split('.');
    if (
            domainNameParts.length < 2 ||
            domainNameParts.some((e) => !e.match(/^[A-Za-z0-9-]+$/))) {
        return [NOT_VALID_EMAIL_MSG];
    }

    return [];
}

export const minLength8Validator = (value) => {
    if (value.length < 8) {
        return [MIN_LENGTH_8_MSG]
    }
    return [];
}

export const printableAsciiValidator = (value) => {
    // Space is also considered printable, but we exclude it here
    if (!value.match(/^[!-~]+$/)) {
        return [NON_PRINTABLE_ASCII_MSG]
    }
    return [];
}

export const useInput = (validators) => {
    const [value, setValue] = React.useState('');
    const [helperText, setHelperText] = React.useState('');

    const handleBlurOrChange = (event) => {
        let value = event.target.value;
        setValue(value);
        setHelperText(getHelperText(value, validators));
    }

    return [
        {
            value,
            helperText,
            onBlur: handleBlurOrChange,
            onChange: handleBlurOrChange,
            error: !!helperText,
        },
        setValue,
        setHelperText,
    ]
}

export const validateTextFields = (alertTextSetter, propsSeq) => () => {
    // Note that this only indicates validity of fields that have had blur or change events previously triggered,
    // as it checks error input property, which is based on false helperText property, and helperText is set only
    // by handleBlurOrChange.
    let isValid = !propsSeq.map(p => p.error).some(v => v === true);
    alertTextSetter(isValid ? '' : VALIDATION_ERROR_MSG);
    return isValid;
}

export const getAlertTextFromResponse = ({ status, data, headers: { 'content-type': contentType } }) => {
    // Only show plain text alerts to user, in case we switch to a
    // better serialization standard (e.g. JSON). We could also maintain
    // backwards compatibility here.

    // Assume encoding is something sane, like UTF-8 or ASCII
    if (!contentType || !contentType.split(';')[0] === 'text/plain') {
        console.debug(`Unexpected response. Status: ${status}. Data: ${JSON.stringify(data)}`)
        return UNKNOWN_ERROR_MSG;
    }
    else {
        return data;
    }
}
