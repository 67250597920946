import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

const LoginFooter = () => {
    return <Box sx={{
                position: 'fixed', bottom: '0', left: '0', right:'0',
                paddingTop:'5px', paddingBottom:'10px',
                '& .MuiLink-root': {
                    color: 'white'
                },
                '& .MuiLink-root:hover': {
                    color: '#fec503',
                }
            }}
            >
                <Grid container direction="row" justifyContent="space-around" spacing={2}
                    sx={{fontSize:'75%' }}
                >
                    <Grid item xs={1}/>
                    <Grid item xs={7} >
                        <Link href="https://premonition.software/" underline="none" target="_blank">
                            © 2015-2024 Premonition Software Limited
                        </Link>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent="flex-end">
                            <Link href="https://premoni.s3.eu-west-2.amazonaws.com/Premonition Privacy Policy.pdf"
                                underline="none" target="_blank">Privacy Policy</Link>
                        </Grid>
                    </Grid>
                    <Grid item xs={1}/>
                </Grid>
            </Box>
}

export { LoginFooter }
