import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grow from '@mui/material/Grow';
import { useAuthActions } from 'auth/actions';
import { contactDialogOpenAtom } from 'auth/state';
import { ContactDialog } from 'auth/components/contact-dialog';
import { RegisterDialog } from 'auth/components/register-dialog';
import { ConfirmDialog } from 'auth/components/confirm-dialog';

import {
    emailValidator,
    requiredValidator,
    useInput,
    getHelperText,
} from './form'

import { useInitialRedirectHelper } from 'common/initial-redirect-helper';

import { useRecoilState } from 'recoil';

const USER_EMAIL_VALIDATORS = [requiredValidator, emailValidator]
const USER_PASSWORD_VALIDATORS = [requiredValidator]

const LoginDialog = ({open, setOpen}) => {
    const [userEmailProps, setEmailValue, setEmailHelperText] = useInput(USER_EMAIL_VALIDATORS);
    const [userPasswordProps, setPasswordValue, setPasswordHelperText] = useInput(USER_PASSWORD_VALIDATORS);
    const [preLogin, setPreLogin] = React.useState(true);
    const [alertText, setAlertText] = React.useState('');
    const authActions = useAuthActions();
    const initialRedirectHelper = useInitialRedirectHelper();

    const close = (event, reason) => {
        console.debug(reason)
        if (reason && reason === 'backdropClick') return
        setOpen(false)
        clearForm()
    }

    const clearForm = () => {
        setEmailValue('')
        setPasswordValue('')
        setEmailHelperText('')
        setPasswordHelperText('')
        setAlertText('')
        setPreLogin(true)
    }

    const validateForm = () => {
        var invalidEmailMessage = null;
        var invalidPasswordMessage = null;

        if (preLogin) {
            // getHelperText runs the validators
            invalidEmailMessage = getHelperText(userEmailProps.value, USER_EMAIL_VALIDATORS)
            if (invalidEmailMessage) {
                setEmailHelperText(invalidEmailMessage)
                return false
            }
        } else {
            // getHelperText runs the validators
            invalidEmailMessage = getHelperText(userEmailProps.value, USER_EMAIL_VALIDATORS)
            if (invalidEmailMessage) setEmailHelperText(invalidEmailMessage)

            invalidPasswordMessage = getHelperText(userPasswordProps.value, USER_PASSWORD_VALIDATORS)
            if (invalidPasswordMessage) setPasswordHelperText(invalidPasswordMessage)

            if (invalidEmailMessage || invalidPasswordMessage) return false
        }

        return true
    }

    const handleSubmit = async () => {

        if (!validateForm()) return

        var loggedIn = false;
        var finalDestination = await initialRedirectHelper.getFinalDestination()

        try {
            if (preLogin) {
                // Pass final destination to preLogin, so it can be applied to a 3rd party auth redirect.
                var preLoginResponse = await authActions.preLogin(userEmailProps.value, finalDestination);

                if (preLoginResponse.redirect?.startsWith('http')) {
                    // console.debug('/pre_login redirect: ' + preLoginResponse.redirect)
                    window.location = preLoginResponse.redirect
                    return false
                }

                setPreLogin(false);
            } else {
                await authActions.login(userEmailProps.value, userPasswordProps.value);
                var localInfo = null;
                try {
                    localInfo = await authActions.fetchAuthInfo();
                } catch (error) {
                    console.warn(JSON.stringify(error))
                    setAlertText("Unfortunately there was a local network issue and your login attempt has failed, " +
                        "please try again later.");
                    return false;
                }
                loggedIn = true;

                await initialRedirectHelper.initialRedirect(localInfo)
                return false
            }
            setAlertText('');
        } catch (error) {
            console.warn(JSON.stringify(error))
            setAlertText('Incorrect username or password.');
        }

        if (loggedIn) {
            close();
        }
    };

    return <Dialog open={open} onClose={ close } maxWidth="sm">
            <DialogTitle>Login</DialogTitle>
            <IconButton
                    aria-label="close"
                    onClick={ close }
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                <CloseIcon />
            </IconButton>

            <form>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DialogContentText>
                            Enter login details below
                        </DialogContentText>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            autoComplete="username"
                            id="login-email-address"
                            label="Email address"
                            type="email"
                            variant="outlined"
                            fullWidth
                            required
                            disabled={!preLogin}
                            { ...userEmailProps }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grow in={!preLogin}>
                            <TextField
                                autoComplete="current-password"
                                id="login-password"
                                label="Password"
                                type="password"
                                variant="outlined"
                                fullWidth
                                required
                                { ...userPasswordProps }
                            />
                        </Grow>
                    </Grid>
                    <Grid item xs={12}>
                        <Grow in={alertText !== ''}>
                            <div>{ alertText ? <Alert severity="error">{alertText}</Alert>: null }</div>
                        </Grow>
                    </Grid>

                    <Grid container justifyContent="flex-end" pt={2}>
                        <Button
                            id={"login-submit"}
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            Log In
                        </Button>
                    </Grid>

                </Grid>
            </DialogContent>
            </form>
        </Dialog>
}

const LoginViewInt = () => {
    var queryParams = new URLSearchParams(window.location.search)
    var initialLoginOpen = false
    if (queryParams.get('final_destination')) initialLoginOpen = true

    const [open, setOpen] = React.useState(initialLoginOpen)
    const [contactDialogOpen, setContactDialogOpen] = useRecoilState(contactDialogOpenAtom)
    const [registerDialogOpen, setRegisterDialogOpen] = React.useState(true)
    const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(true)

    const handleLogInButtonClick = () => setOpen(true);

    return (
        <Stack alignItems="center" spacing={2} useFlexGap>
            <Box mt={20}>
                <img src="/Premoni_logo_transparent_249.png" alt="Premoni Logo" width='249' />
            </Box>

            <Typography ml={13} mt={-2} color="white"
                sx={{ fontFamily: 'Droid Serif', fontStyle:'italic', fontSize:'22px' }}
            >expert applications</Typography>

            <Button variant="contained" onClick={handleLogInButtonClick}
                sx={{mt:14, fontSize:'22px', padding:'18px 39px', textTransform:'none', borderRadius:'7px'}}
            >Log&nbsp;In</Button>

            <LoginDialog open={open} setOpen={setOpen}/>

            <ContactDialog open={contactDialogOpen} setOpen={setContactDialogOpen}/>

            <Route path="/register/:code">
                <RegisterDialog open={registerDialogOpen} setOpen={setRegisterDialogOpen}/>
            </Route>

            <Route path="/confirm/:code">
                <ConfirmDialog open={confirmDialogOpen} setOpen={setConfirmDialogOpen}/>
            </Route>
        </Stack>
    )
}

const LoginView = withRouter(LoginViewInt);
export { LoginView };
