import { useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Link from '@mui/material/Link';
import { useAuthActions } from 'auth/actions';
import { emailValidator } from 'auth/components/form';
import { Formik, Form } from "formik";
import { StringField, MultilineField, SwitchField } from 'common/form';
import * as yup from "yup";

const ContactForm = ({formik}) => {

    var acceptGoogleTcLabel = <>
        <span>This site is protected by reCAPTCHA and the </span>
        <Link href='https://policies.google.com/privacy' target="_blank">Google&nbsp;Privacy&nbsp;Policy</Link>
        <span> and </span>
        <Link href='https://policies.google.com/terms' target="_blank">Terms&nbsp;of&nbsp;Service</Link>
        <span> apply. Please indicate your consent to Google storing 3rd party cookies in your browser. *</span>
    </>

    return <Form>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <StringField formik={formik} name="name" label="Name *" autoComplete="name" />
            </Grid>

            <Grid item xs={12}>
                <StringField formik={formik} name="email" label="Email *" autoComplete="email"/>
            </Grid>

            <Grid item xs={12}>
                <StringField formik={formik} name="phone" label="Phone" autoComplete="tel"/>
            </Grid>

            <Grid item xs={12}>
                <MultilineField formik={formik} name="message" label="Message *" autoComplete="off"/>
            </Grid>

            <Grid item xs={12}>
                <SwitchField formik={formik} name="accept_google_tc" label={acceptGoogleTcLabel} />
            </Grid>

            <Grid container justifyContent="flex-end" pt={2}>
                <Button id={"contact-submit"} variant="contained" type="submit">Contact</Button>
            </Grid>
        </Grid>
    </Form>
}

const OutcomeContent = ({message, handleClose}) => {
    return <Grid container spacing={2}>
        <Grid item xs={12}>
            {message}
        </Grid>

        <Grid container justifyContent="flex-end" pt={2}>
            <Button id={"contact-ok"} variant="contained" onClick={handleClose}>OK</Button>
        </Grid>
    </Grid>
}

const ContactDialogInt = ({formik, open, setOpen, contactOutcome, setContactOutcome}) => {

    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick') return
        setOpen(false)
        formik.resetForm()
        setContactOutcome({successMessage: null, failureMessage: null})
    }

    var content = null;
    if (contactOutcome.successMessage) {
        content = <OutcomeContent message={contactOutcome.successMessage} handleClose={handleClose}/>
    } else if (contactOutcome.failureMessage) {
        content = <OutcomeContent message={contactOutcome.failureMessage} handleClose={handleClose}/>
    } else {
        content = <ContactForm formik={formik}/>
    }

    return <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <DialogTitle>Contact</DialogTitle>
        <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
        >
            <CloseIcon />
        </IconButton>
        <DialogContent>{content}</DialogContent>
    </Dialog>
}

const ContactDialog = ({open, setOpen}) => {
    const authActions = useAuthActions()
    const [contactOutcome, setContactOutcome] = useState({successMessage: null, failureMessage: null})

    const contactFormSchema = yup.object().shape({
        name: yup.string().required("Required"),
        email: yup.string().required("Required").test(
            'valid-email',
            'Not a valid email address',
            (value, context) => emailValidator(value).length === 0),
        phone: yup.string(),
        message: yup.string().required("Required"),
        accept_google_tc: yup.boolean().test(
            "required-true",
            "Required",
            (value, context) => value === true),
    })

    const onSubmit = async (values, actions) => {
        values.recaptcha_token = await window.grecaptcha.execute(
            process.env.REACT_APP_RECAPTCHA_SITE_KEY, {action: 'contact'})

        try {
            await authActions.contact(values)
            setContactOutcome({successMessage: "Your message has been sent."})
        } catch(error) {
            console.error(`ContactDialog response error: ${error}`)
            if (error.response.status === 400 && error.response.data) setContactOutcome({failureMessage: error.response.data})
            else setContactOutcome({failureMessage: "Unexpected error. Please contact support."})
        }
    }

    return <Formik
        component={ (formik) => <ContactDialogInt formik={formik} open={open} setOpen={setOpen}
            contactOutcome={contactOutcome} setContactOutcome={setContactOutcome} /> }
        initialValues={{ name: '', email: '', phone: '', message: '', accept_google_tc: false }}
        validationSchema={contactFormSchema}
        onSubmit={onSubmit}
    />;
}

export { ContactDialog }
