import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';

const FormikField = ({component, formik, ...rest}) => {
    const Tag = component
    return <Tag
        {...rest}
        value={formik.values[rest.name]}
        error={formik.touched[rest.name] && Boolean(formik.errors[rest.name])}
        helperText={formik.touched[rest.name] && formik.errors[rest.name]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
    />
}

const StringField = ({formik, ...rest}) => {
    return <FormikField component={TextField} formik={formik}
        id={rest['name']}
        {...rest}
        type="string" variant="outlined" fullWidth
    />
}

const MultilineField = ({formik, ...rest}) => {
    return <FormikField component={TextField} formik={formik}
        id={rest['name']}
        {...rest}
        type="string" variant="outlined" fullWidth multiline rows={4}
    />
}

const SwitchField = ({formik, name, label}) => {
    //console.log(`SwitchField formik: ${JSON.stringify(formik)}`)

    // Unlike TextField, Switch does not support error or helperText so we add equivalent UX.
    var labelSx = formik.touched[name] && Boolean(formik.errors[name]) ? {
        color: 'rgba(193, 52, 37, 0.5)',
    } : null

    var errorTextSx = formik.touched[name] && Boolean(formik.errors[name]) ? {
        color: 'rgba(193, 52, 37, 0.5)',
        fontSize: '0.75rem',
        marginLeft: '16px',
        marginTop:'5px',
        letterSpacing: '0.03333em',
        lineHeight: '1.66',
        fontWeight: '400'
    } : null

    return <>
        <FormControlLabel
            sx={labelSx}
            id={`${name}-label`}
            name={`${name}_label`}
            control={(
                <Switch
                    id={`${name}`}
                    name={`${name}`}
                    checked={formik.values[name]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
            )}
            label={label}
        />
        {errorTextSx ? <Box sx={errorTextSx}>{formik.errors[name]}</Box> : null}
    </>

}

export { FormikField, StringField, MultilineField, SwitchField }
