import { atom } from 'recoil';

const authAtom = atom({
    key: 'auth',
    // value of null represents a pending login check
    // false represents not logged in
    // truthy (containing the auth info object) represents logged in
    default: null
});

const contactDialogOpenAtom = atom({
    key: 'contactDialogOpen',
    default: false
});

export { authAtom, contactDialogOpenAtom };
